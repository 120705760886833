import { Fragment, useContext } from 'react';
import {motion} from 'framer-motion'
import {CustomContext} from '../../config/context/context';
import CardCatalog from '../CardCatalog/CardCatalog';
import { useNavigate, useParams } from 'react-router-dom';
import ColorSort from '../ColorSort/ColorSort';
import './Catalog.css'




const imgAnimation = {
    hidden: {
        x:1000,
        opacity:0
    },
    visible: {
        x:0,
        opacity:1,
        transition: { duration: 1}
    },
}
const textAnimation = {
    hidden: {
       
        opacity:0
    },
    visible: custom =>( {
        opacity:1,
        transition: {delay: custom * 1.5}
    }),
}


const Catalog = () => {

    const {capItem,getCapItem} = useContext(CustomContext)
    const params = useParams()
    const navigate = useNavigate()
    let ind = 0
    
    getCapItem()

    const CardSee = (item, index) => (
        <Fragment key={item.id}>
          {(index === 4 ) ? CardOption() : ''}
          <CardCatalog item={item}/>
        </Fragment>
    )

    const CardOption = () => (
        <motion.div 
            initial="hidden"
            whileInView="visible"
            viewport={{amount:0.5}}
            className='CardOption__title'> 
            <motion.div variants={imgAnimation} className='CardOption__image'><img src={"/pic/navy-red.png"} alt=""/></motion.div>
            <motion.div custom={1} variants={textAnimation}div className='CardOption__image__text'>ВЫШИВКА</motion.div>
            <motion.div custom={1.5} variants={textAnimation} className='CardOption__text'>Если вы хотите бейсболку с вышивкой, просто добавьте ее в корзину и при оформлении заявки поставьте галочку на пункте "Хочу вышивку".<br />
            Далее мы с вами свяжемся, обсудим эскиз и все ваши пожелания.</motion.div>
        </motion.div>
    )
    

    return (

        
        <div  className="CatalogWrapper">
            <div className='CatalogChange'>
                <div className='CatalogChangeVisor'>
                    <div><img src={"/pic/visorp.png"} alt="" onClick={ () =>  navigate('/catalog/pr/') }/></div>
                    <div><img src={"/pic/capClassic.png"} alt="" onClick={ () =>   navigate('/catalog/iz/') }/></div>
                </div>
                <div className='CatalogChangeColor'>
                    <ColorSort />
                </div>
            </div>
            <div className='CatalogEmbroidery'>
                <h2>ВЫШИВКА</h2>
                <div >Если вы хотите бейсболку с вышивкой, просто добавьте ее в корзину и при оформлении заявки поставьте галочку на пункте "Хочу вышивку".<br />
                    Далее мы с вами свяжемся, обсудим эскиз и все ваши пожелания.
                </div>
            </div>
             {/* <ColorSort /> */}
             {( params.visortype === undefined && params.color === undefined) ? capItem.map((item) => CardSee(item, ind=ind+1)) : 
                capItem.map((item) =>((params.visortype === item.visortype && params.color === undefined) ? CardSee(item, ind=ind+1) : 
                    (params.visortype === item.visortype) ? item.colortype.map((el) => ((params.color === el) ? CardSee(item, ind=ind+1) : '')) : ''
                 ))}
             {/* { capItem.map((item) =>(
                    item.color.map((el) => ((params.color === el) ? CardSee(item) : ''))
                ))
            }  */}
            {/* {capItem.map((item) =>((params.visortype === item.visortype && params.color === undefined) ? CardSee(item) : 
                '' ))} */}
        </div>
    );
};

export default Catalog;