import Slider from "./Slider";
import data from "./data";
import "./Carousel.css";


const Carousel = () => {


    return (
        <div className="center">
             <Slider data={data} activeSlide={2} />
        </div>
    )
}


export default Carousel