import { createContext, useState } from "react";
import data from '../../product/products.json'

export const CustomContext = createContext()


const Context = (props) =>{


    const [basket, setBasket] = useState([])
    let [capItem, setCapItem] = useState(data)
    let [CartCount, setCartCount] = useState(0)
    const [showForm, setShowForm] = useState(0)
    // let [PathName, setPahtName] = useState['']
    // let basketItem = []
    

    const getCapItem = () =>{

        capItem = data
        console.log(capItem)
    }

   
    // const getCartCount = (count) => {
    //     CartCount = 2
    //     console.log(count + "сеткарткаунт")
    // }


    // добавление в коризну
    
    const basketNew = (item,colr) =>{
        // basketItem.push({...item, count : 1})
        // setBasket(basketItem)
       // item.count=1
        setBasket(prev => [...prev, {...item, id: item.id + colr, color: colr, count : 1}])
        // item.count=1
        // basket.push(item)
        // setCartCount(CartCount = basket.length)
        console.log("длинна при добавлении" + basket.length  + "содержимое баскет " + basket )
        setCartCount(CartCount= CartCount+1)


    }



    const ItemMinus = (item) =>{

        // useEffect

        // console.log(item.count + ' отслеживает каунт итем')

        // последний вариант
        // if(item.count === 1){
        //     setBasket(prev => prev.filter(product => product.id !== item.id ))
        //     setCartCount(CartCount = CartCount - 1)
        // }else{
        //     setBasket(prev => prev.map(product => product.id === item.id  ? [...prev, {...product, count: product.count -1 }]  : ''))
        // }
        
        if(item.count === 1){
            setBasket(prev => prev.filter(product => product.id !== item.id ))
            setCartCount(CartCount = CartCount - 1)
        }else{
            item.count--
        }
        // (item.count === 1) ? setBasket(prev => prev.filter(product => product.id !== item.id )) : item.count--

        // (item.count === 1) ? setBasket(prev => prev.filter(product => product.id !== item.id )) :
        //     setBasket(prev => prev.map(product => product.id === item.id  ? {...product, count: product.count -1 }  : ''))
        // setCartCount(CartCount = CartCount - 1)
    }

    const BasketPlus = (item) =>{
        // let changeCount = item.count++
        item.count++
        // console.log(basketItem + "basketItem")
        // setBasket( prev => ({count: prev.count + 1}) )
        // setBasket(basket.some(el => el.id === item.id) ? (prev => ({...prev,{ ...prev, count:2}})) : '')
        //setBasket(basket[item.count])
        // console.log(basket[...prev] + "изменение каунт")
        // basketItem = basket
        // setBasket(basket)


        // basketItem=basket
        // const itemIndex = basketItem.findIndex(value => value.id === item.id);
        // console.log(itemIndex + '= itemindex + basket.count=   ' + basketItem[itemIndex].count)
        // basketItem[itemIndex].count++

        // setBasket(basketItem)


        // setBasket([...basket[item],{...basket[itemIndex],count: basket[itemIndex].count+1}])

        //  setBasket({...basket[0],count: basket[0].count+1})
        // setBasket(prev => prev.map(product => product.id === item.id  ? {...item, count: item.count +1 }  : ''))
        

    }

    const addBasket = (item, colr) =>{
        basket.some(el => el.id === item.id+colr) ? BasketPlus(item,colr) : basketNew(item,colr)
        //  basket.some(el => el.id === item.id) ? basketPlus(item) : basketNew(item)
        // basket.some(el => el.id === product.id) ? setBasket( product.id => ([...product ,{count : prev.count + 1}])) : basketNew(product)  
        console.log(basket + "добавление")   
    }
    //
    const BasketClear = () =>{
        setCartCount(0)
        setBasket([])
    }

    let value = {
        capItem,  setCapItem, getCapItem, addBasket, basket, CartCount, setBasket,ItemMinus, basketNew,BasketPlus, showForm, setShowForm,BasketClear
    }

    return <CustomContext.Provider value={value}>
        {
            props.children
        }   
    </CustomContext.Provider>

}

export default Context