import React, { useContext, useState} from 'react';
import { CustomContext } from '../../config/context/context';
import { MdShoppingCart } from "react-icons/md";
import './CardCatalog.css'
import { useNavigate } from 'react-router-dom';
// import { fs } from 'fs';
// import { constants } from 'fs';\
// import { promises as fs } from "fs";


const CardCatalog = ({item}) => {

    const {basket,basketNew} = useContext(CustomContext)
    let [BigImage, setBigImage] = useState(1)
    const navigate = useNavigate()

    // BigImage= item.img
    // console.log(BigImage + "= BigImage")
    let col = item.color
    // let itemcolColor = 1
    
    
    

    const [ColorItem, setColorItem] = useState(col[0])

    // заменяем цвета на цвета понятные css
    const colorChange = (el) =>{
        return (el === 'dark navy') ? el = 'midnightblue' : 
                    (el === 'dark heather') ? el = 'gainsboro' : 
                      (el === 'dark grey') ? el = '#3D3D3D' : 
                        (el === 'grey' ||  el === 'charcoal') ? el = '#9D9D9D' : 
                            (el === 'royal') ? el = 'blue' :
                                (el === 'heather grey' || el === 'heather') ? el = '#bfbfbf' : 
                                    (el === 'spruce' || el === 'evergreen' || el === 'tropic green') ? el = 'green' :
                                        (el === 'neon green') ? el = 'greenyellow' : 
                                            (el === 'neon orange') ? el = 'darkorange' : 
                                                (el === 'neon pink') ? el = 'deeppink' :
                                                    (el === 'neon purple') ? el = 'darkviolet' : 
                                                        (el === 'neon silver') ? el = 'silver' : 
                                                            (el === 'natural') ? el = 'beige' : 
                                                                (el === 'caramel') ? el = 'chocolate' : 
                                                                    (el === 'cranberry') ? el = 'indianred' : 
                                                                        (el === 'coyote brown') ? el = 'brown' :
                                                                            (el === 'camo') ? el = '#76785E' :
                                                                                (el === 'multicam') ? el = '#5a613f' :
                                                                                    (el === 'slant') ? el = '#b8a78b' :
                                                                                        (el === 'arid' || el === 'arid brown') ? el = '#cbbbac' : el
    }

    //Закрашиваем цвета
    const colorGradien =(el) =>{
        let gCol = el.split('-')
        // console.log(colorChange(gCol[0]) + "цвет тут 0")
        gradItem = 'linear-gradient(-45deg, '+ colorChange(gCol[1]) +' 49%, '+ colorChange(gCol[0]) +' 50%)'

        return (<div className='ColorLiner' style={{background: gradItem, minWidth: '20px', minHeight: '20px'}} onClick={ () => {
                setBigImage(1) 
                setColorItem(el)
                 
                }}></div>)
        
    }

    // const fileSearch = (fileName) => {
    //     var fs = require('fs');

    //     fs.access(item.img +  ColorItem + "/mini/" + fileName + ".png", function(error){
    //         if (error) {
    //             console.log("Файл не найден");
    //             return true
    //         } else {
    //             console.log("Файл найден");
    //             return false
    //         }
    //     });
    // }


    let gradItem = ''


    return (
        
        <div className='catalog__card'> 
            <div className='catalog__card__img'>
                <div className='catalog__card__image'>
                    <img src={item.img + ColorItem + '/' + BigImage + ".png"}  alt="" />
                    <div className='catalog__card__option__color'>
                        {col.map((el) => (
                            el.indexOf('-') === -1 ? (<div className='ColorLiner' style={{background: colorChange(el), minWidth: '20px', minHeight: '20px'}} onClick={ () => {
                                setBigImage(1) 
                                setColorItem(el)
                            }}></div>) : colorGradien(el)

                        ))}         
                    </div>    
                   
                </div>
                <div className='catalog__card__image__mini'>
                    {/* {console.log(item.img +  ColorItem + "/3.png")} */}
                      <div className='catalog__card__image__mini1'><img src={item.img +  ColorItem + "/mini/1.png"} alt="" onClick={ () => setBigImage(1)}/></div>
                      <div><img src={item.img +  ColorItem + "/mini/2.png"} alt="" onClick={ () => setBigImage(2)} /></div>
                      <div><img src={item.img +  ColorItem + "/mini/3.png"} alt="" onError={i => i.target.style.display='none'}  onClick={ () =>{setBigImage(3)}}/></div>
                      {/* <div><img src={item.img +  ColorItem + "/mini/3.png"} alt="" onClick={ () => setBigImage(3)}/></div> */}
                      {/* {fileSearch(4) === true ? <img src={item.img +  ColorItem + "/mini/4.png"} alt="" onClick={ () => setBigImage(4)}/> : ''} */}
                       <div className='catalog__card__image__mini4'><img src={item.img +  ColorItem + "/mini/4.png"} alt="" onError={i => i.target.style.display='none'}  onClick={ () =>{setBigImage(4)}}/></div>
                      {/* <img src={item.img +  ColorItem + "/mini/4.png"} alt="" onLoad={()=> itemcolColor = 4}  onError={()=> itemcolColor = 1} onClick={ () => setBigImage(itemcolColor)}/> */}
                </div>

            </div>
            <div className='catalog__card__option'>
                 <h2>{item.title}</h2>
                {/* <div className='catalog__card__option__title'><h2>{item.title}</h2></div> */}
                <div className='catalog__card__option__full'>
                    <b>Состав :</b>  {item.compound}
                    <div className='catalog__card__option__full__row'>
                        <div className='catalog__card__option__full_left'>
                            <div><b>код товара: </b> {item.id}</div>
                            <div><b>размер:    </b>{item.size} </div>
                            <div><b>Цвет:   </b>{ColorItem}</div>
                        </div>
                        <div className='catalog__card__option__full_left'>
                            <div><b>пол:   </b>{item.sex} </div>
                            <div><b>клинья:   </b>  {item.desc}</div>
                        </div>
                        </div>
                    <div className='catalog__card__option__full__price'>
                        <div className='catalog__card__option__full__price__price'><b>стоимость: </b>{item.price}р</div>
                        {basket.some(el => el.id === item.id+ColorItem) ?  <div className='Catalog__card__option__full_left__inBasket' onClick={ () =>{navigate('/cart')}}>товар уже в корзине</div> :
                                                    <button className='catalog__card__basket__button'  onClick={ () => {
                                                        basketNew(item, ColorItem)
                                                    }}> добавить в  <MdShoppingCart style={{width : "25px", marginLeft: "10px", color:"yellow"}} /> </button> } 
                        
{/* {basket.some(el => el.id !== item.id) ?  <button className='catalog__card__basket__button'  onClick={ () => {
                                                                        addBasket(item, ColorItem)
                                                                            }}>добавить в <MdShoppingCart /></button> : 
                                                                            <p>товар уже в корзине</p>} */}

                    </div>
                </div>
           
                

            </div>
        </div>
    );
};

export default CardCatalog;