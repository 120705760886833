
export default [
  {
    id: 1,
    bgColor: "#F54748",
    // icon: <FontAwesomeIcon icon={faCameraRetro} size="3x" />,
    Image: "/pic/wallP.jpg"
  },
  {
    id: 2,
    bgColor: "#7952B3",
    // icon: <FontAwesomeIcon icon={faGlassCheers} size="3x" />,
    title: "Lorem Ipsum",
    Image: "/pic/wallP.jpg",
    desc:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
  },
  {
    id: 3,
    bgColor: "#1597BB",
    // icon: <FontAwesomeIcon icon={faDragon} size="3x" />,
    title: "Pirate Cap",
    Image: "/pic/project/003.jpg",
    desc:
      ""
  },
  {
    id: 4,
    bgColor: "#185ADB",
    // icon: <FontAwesomeIcon icon={faFootballBall} size="3x" />,
    title: "Mad Moto",
    Image: "/pic/project/004.jpg",
    desc:
      ""
  },
  {
    id: 5,
    bgColor: "#FF616D",
    // icon: <FontAwesomeIcon icon={faHelicopter} size="3x" />,
    title: "Lorem Ipsum",
    Image: "/pic/wallP.jpg",
    desc:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
  },
  {
    id: 6,
    bgColor: "#7952B3",
    // icon: <FontAwesomeIcon icon={faHelicopter} size="3x" />,
    title: "Lorem Ipsum",
    Image: "/pic/wallP.jpg",
    desc:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
  },
  {
    id: 7,
    bgColor: "#1597BB",
    // icon: <FontAwesomeIcon icon={faHelicopter} size="3x" />,
    title: "Lorem Ipsum",
    Image: "/pic/wallP.jpg",
    desc:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
  }
];
