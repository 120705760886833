import React, { useContext, useState} from 'react';
import { CustomContext } from '../../config/context/context';
import './Card.css'

const Card = ({item}) => {

    const {ItemMinus,BasketPlus} = useContext(CustomContext)
    const [rerender, setRerender] = useState(false);
    const forceUpdate = () => setRerender(!rerender);


    return (
        <div className='basket__card' key={item.id}> 
            <div className='basket__card__image'>
                <img src={item.img + item.color  + "/1.png"} alt="" />
            </div>
            <div className='basket__card__title'>
                <h2>{item.title}</h2>
                <p> Цвет : {item.color}</p>
            </div>
            <div className='basket__card__col'>
                <div className='basket__card__col__minus'>
                    <button  onClick={ () => {
                        ItemMinus(item)
                        forceUpdate() 
                     }}>-</button>
                </div>
                <div className='basket__card__col__col'>{item.count}</div>
                <div className='basket__card__col__plus'>
                    <button  onClick={ () => {
                          BasketPlus(item)
                          forceUpdate() 
                        }}>+</button>
                </div>
                {/* <div className='basket__card__col__totalprice'>Стоимость : {item.price*item.count}р</div> */}

            </div>
            <div className='basket__card__col__totalprice'>Стоимость : {item.price*item.count}р</div>
        </div>
    );
};

export default Card;