import {  useRef, useState } from "react";
import { FaBars, FaTimes, FaWhatsapp, FaPhoneAlt, FaTelegram } from "react-icons/fa";
import "./Navbar.css";

function Navbar() {
	const navRef = useRef();
	const [show, setShow] = useState(false)

	const showNavbar = () => {
		navRef.current.classList.toggle(
			"responsive_nav"
		);
	};


	// const handleClickOutside = (event) => {
	// 	// var container = document.getElementById('header__contact');
	// 	if (navRef.current && !navRef.current.contains(event.target)) {
	// 		setShow(false)
	// 	}
	// };

	// useEffect(() => {
    //     document.addEventListener('click', handleClickOutside, true);
    //     return () => {
    //         document.removeEventListener('click', handleClickOutside, true);
    //     };
    // }, []);

	return (
		<div className="header">
			<nav ref={navRef}>
				<a href="/#">Доставка</a>
				<div className="header__cont" onClick={ () => setShow(!show)}>Контакты</div>
				{show  &&  <div className="header__contact"  onMouseIn={() => setShow(false)}>
								<div className="header__contact__tel"><FaPhoneAlt style={{"width": "22px"}}/><a href="tel:+79851205050">+7(985)120-50-50</a></div>
								<div className="header__contact__tel"><FaWhatsapp style={{"width": "22px"}}/><a href="https://wa.me/79851205050">+7(985)120-50-50</a></div>
								<div className="header__contact__gram">
									<div><FaTelegram color="green" style={{"padding-right": "0px", "color":"SteelBlue"}}/></div>
									<div><a href="https://t.me/CustomCap">@CustomCap</a></div>
								</div>
							</div>}
				{/* <a href="/#">Blog</a> */}
				{/* <a href="/#">About me</a> */}
				<button
					className="nav-btn nav-close-btn"
					onClick={showNavbar}>
					<FaTimes />
				</button>
			</nav>
			<button
				className="nav-btn"
				onClick={showNavbar}>
				<FaBars />
			</button>
		</div>
	);
}

export default Navbar;