import { useLocation, useNavigate } from 'react-router-dom';
// import { useContext, useState } from "react";
import './HighCatalog.css'
// import ColorSort from '../ColorSort/ColorSort'
// import Catalog from '../Catalog/Catalog';
// import { CustomContext } from '../../config/context/context';

const HighCatalog = () => {

    const navigate = useNavigate()
    const location = useLocation()
    // const [show, setShow] = useState(false)
    // let {setPathName} = useContext(CustomContext)

    // const ChangePath = (el) =>{
    //     setShow(true)
    //     setPathName(el)
    // }

    

    return (
        <div  className="all">
            <h2>Выбери свою бейсболку</h2>
            {/* <div className='HighCatalog__Hiphop__cap'>
                <div className='HighCatalog__Hiphop__cap__img'>
                    <img src="/pic/wallP.jpg" alt="" />
                </div>
                <div className='HighCatalog__cap__text'>
                    <div className='HighCatalog__cap__text__Hiphop'>
                        <p>Бейсболка с <br/>прямым козырьком</p>
                    </div>
                    <div className='HighCatalog__cap__text__classic'>
                        Классическая  <br/>бейсболка
                    </div>
                </div>
                <div className='HighCatalog__Classiccap__img'>
                    <img src="/pic/wallP.jpg" alt="" />
                </div>
            </div>      */}

            <div className='HighCatalog__all'>
                    <div className='HighCatalog__Hiphop__cap__img_1' onClick={() => (location.pathname === '/') ?  navigate('/catalog/pr') :''}>
                            
                        <img src="/pic/wallP.jpg" alt="" />
                        <div className='HighCatalog__cap__text__Hiphop_1'>
                            <p>Бейсболка с <br/>прямым козырьком</p>
                        </div>
                    </div>

                    <div className='HighCatalog__Classic__cap__img_1' onClick={() => (location.pathname === '/') ?  navigate('/catalog/iz') :''}>
                        <img src="/pic/wallC.jpg" alt="" />
                        <div className='HighCatalog__cap__text__classic_1'>
                             Классическая  <br/> бейсболка
                         </div>
                    </div>

            </div>       
            {/* {show  && <ColorSort /> } */}

        </div>
    );
};

export default HighCatalog;