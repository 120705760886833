import React from 'react'
import Header from './header/Header'
import { Outlet } from 'react-router-dom'
import Footer from './Footer/Footer';

const  Layout = () => {
   return (
        <>
            <Header/>
            
            <Outlet/>

            <Footer />
        </>
   );
}


export default Layout;