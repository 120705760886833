import React, { useContext} from 'react'
import './BasketFormFinish.css'
import { useNavigate } from 'react-router-dom'
import { CustomContext } from '../../config/context/context'


const BasketFormFinish = () => {

    let {BasketClear,setShowForm} = useContext(CustomContext)
    const navigate = useNavigate()

    const  FinishBasket = () =>{
        setShowForm(0)
        navigate('/')
        BasketClear()

    }

    
    return (
      <div className='basketfinish__title'>
         <div className='baske__finish__border'>
            <h3>Благодарим за покупку!!!</h3> 
            <p>мы обработаем Ваш заказ и сразу с вами свяжемся!</p>

            <div className='baske__finish__button' onClick={() => {FinishBasket()}}>закрыть</div>
         </div>
      </div>
    )
  
}

export default BasketFormFinish;