import React from 'react'
import {motion} from 'framer-motion'
import "./Portfolio.css"




const imgAnimation = {
  hidden: {
      x:700,
      opacity:0
  },
  visible: custom =>( {
      x:0,
      opacity:1,
      transition: { delay: custom * 1, duration: 1}
  }),
}

const textAnimation = {
  hidden: {
      opacity:0
  },
  visible: custom =>( {
      opacity:1,
      transition: {delay: custom * 1.5, duration: 1}
  }),
}



const Portfolio = () => {


  return (
    <motion.div initial="hidden"
    whileInView="visible"
    // viewport={{amount:0.5}}
    viewport={{ once: true }}
    className='Portfolio'>
        <motion.div  variants={imgAnimation} custom={1}
            className='Portfolio__Mirror'>
          <motion.div variants={textAnimation} custom={2}  className='Portfolio__title'>УНИКАЛЬНАЯ</motion.div>
          <motion.div variants={textAnimation} custom={2} className='Portfolio__title__cap'>БЕЙСБОЛКА</motion.div>
          <motion.div variants={textAnimation} custom={3} className='Portfolio__title__text'>Мы создадим для тебя уникальную вещь. Просто выбери бейсболку из нашего большого ассортимента и сделаем вышивку с всеми возможными пожеланиям</motion.div>
        </motion.div>
    </motion.div>
  )
}

export default Portfolio