import { Routes, Route } from 'react-router-dom';
import NotFound from './pages/NotFound/NotFound.jsx';
import Home from './pages/Home/Home.jsx'
import Layout from './components/Layout/Layout.jsx'
import './fonts/Roboto/Roboto-Regular.ttf'
import './App.css'
import Basket from './components/basket/Basket.jsx';
import Catalog from './components/Catalog/Catalog.jsx';


function App() {
  return (
    <>

      <Routes>

        <Route path='/' element={<Layout/>}>
          <Route path='' element={<Home/>} />
          <Route path='/catalog/:visortype/:color?' element={<Catalog/>} />
          <Route path='/catalog/' element={<Catalog/>} />
          <Route path='/cart' element={<Basket/>} />
          <Route path='*' element={<NotFound />} />
        </Route>

      </Routes>
     
    </>
    
    
    
  
  );
}

export default App;
