import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import Context from './config/context/context';
import './fonts/Roboto/Roboto-Regular.ttf'
import './index.css'



const root = createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
      <Context>
        <App />
      </Context>
  </BrowserRouter>
);

