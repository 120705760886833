import React from 'react'
import './History.css'


const History = () => {
    
    return (
      <div className='hystory__title'>
                <div className='hystory__title__p'>В нашей работе мы используем кепки компании FLEXFIT(Yupoong).</div>
                <div className='hystory__title__div'>
                    <div className='Hystory__title__info'>
                        <p>История бренда.</p>
                        <p>Компания Yupoong была основана в 1974 году со штаб-квартирой в Южной Корее.</p>
                        <p>Благодаря своему опыту, профессионализму и добросовестности, компания заслужила безупречную репутацию мирового лидера в производстве головных уборов. 
                        Сейчас компания производит свою продукцию на 4-х крупных фабриках в Бангладеш, Вьетнаме и Южной Корее.</p>
                        В 1992 году Yupoong открыл компанию FLEXFIT EMEI для расширения реализации своей продукции на территории 4-х континентов и выход на рынки более чем в 65 странах.

                    </div>
                    <div className='Hystory__title__img'>
                        <p>Данные кепки используют многие именитые компании</p>
                        <img src='/pic/brands.png' alt='Flexfit'></img>
                    </div>
                </div>
      </div>
    )
  
}

export default History;