import React, { useContext} from 'react'
import './BasketForm.css'
import { useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { CustomContext } from '../../config/context/context'


const BasketForm = () => {


    const TELEGRAM_BOT_TOKEN = '7316527382:AAGjKGdf7I2uebd1_Z6uOTMvCidn7kEie9E';
    const CHAT_ID = '-4265741597';
    const API = 'https://api.telegram.org/bot'+TELEGRAM_BOT_TOKEN+'/sendMessage'


    const {basket,setShowForm} = useContext(CustomContext)

    const {
        register,
        handleSubmit,
        formState:{
            errors
        },
        reset
    } = useForm({
        mode: "onBlur"
    })

    const submitForm = ({name,phone,custom}) => {

        let text = 'Заявка от '+name+'!\nТелефон: '+phone+'!\nВышивка: '+custom ;
        basket.map((item) =>(
            text = text+'\n\n кепка: '+item.id+'\nцвет: '+item.color+'\nколичесво: '+item.count+'\nСтоимость= '+item.price*item.count
        ))


        let response = fetch( API,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                chat_id: CHAT_ID,
                text,
            })
        });
        reset(name='',phone='')
        setShowForm(2)
    }


    
    return (
      <div className='basketfom__title'>
            <form noValidate className="basketfom__title form" onSubmit={handleSubmit(submitForm)}>
                <div className="form__item">
                    <p>Форма отправки заказа</p>
                    <div className='form__input'>
                        <input {...register('name',{
                            required:{
                                message: "Имя обязательно для заполнения",
                                value: true
                            },
                            minLength:{
                                message:'Минимум 2 символа',
                                value : 2
                            },
                            pattern:{
                                message:'Укажите правильно ваше имя',
                                value: /^[а-яА-ЯёЁa-zA-z]+$/ 
                            }
                        })} class="form__input" type="text" placeholder='Введите ваше Имя' />
                        {/* <label class="form__label">Ваше имя</label> */}
                        <p>
                            {errors.name && errors.name?.message}
                        </p>
                    </div>
                    <div className='form__input'>
                        <InputMask mask={'+\\7\\(999)999-99-99'} {...register('phone',{
                                required:{
                                    message: "Телефон обязательно к заполнения",
                                    value: true
                                },
                                pattern:{
                                    message: 'Укажите номер телефона',
                                    // value: /^[\+7](\d{3})\d{3}-\d{2}-\d{2}$/
                                    value : /^\+7\(\d{3}\)\d{3}-\d{2}-\d{2}$/
                                }
                            })} class="form__input" type="text" placeholder='Введите ваш номер телефона' />
                            {/* <label class="form__label">Ваше имя</label> */} 
                            <p>
                                {errors.phone && errors.phone?.message}
                            </p>
                    </div>
                    <div className='form__input'>
                        <input type="checkbox" placeholder="custom" {...register('custom', {required: false})} />
                        Хотите добавить вышивку?
                    </div>
                    <div class='form__submit-button'>
                        <button type="submit">Отправить</button>
                    </div>
                </div>
 

               
                {/* </div> */}
                    {/* <input class="form__input__btn" type="submit" value="Отправить" />
                    <input type="hidden" name="act" value="order" /> */}
               
                {/* <div className='form__button__home' onClick={navigate('/')}>закрыть</div>  */}
                <div className='form_send-result'></div>
                
            </form>
      </div>
    )
  
}

export default BasketForm;