import React  from 'react'
import './ColorSort.css'
import { useLocation, useNavigate } from 'react-router-dom'
// import { CustomContext } from '../../config/context/context'


const ColorSort = () => {
  const navigate = useNavigate()
  const location = useLocation()
  // let {pathName} = useContext(CustomContext)

  const GoLocation = (path ,color) =>(
    (path.indexOf('/catalog/pr') !== -1) ? navigate('/catalog/pr/' + color) : (path.indexOf('/catalog/iz') !== -1) ? navigate('/catalog/iz/'+ color) : ''
  )
    
    return (
      <div className='ColorSortDiv'>
        <div className='ColorSortDiv__text'>
            Выберите ваш любимый цвет
        </div>
        <div className='ColorChange'>
            <div className='ColorBlack' onClick={() => GoLocation(location.pathname, 'black')}></div>
               {/* (location.pathname.indexOf('/catalog/pr') !== -1) ? navigate('/catalog/pr/black') : (location.pathname.indexOf('/catalog/iz') !== -1) ? navigate('/catalog/iz/black') : ''}></div> */}
            <div className='ColorNavy' onClick={() =>  GoLocation(location.pathname, 'navy')}></div>
            <div className='ColorGray' onClick={() =>  GoLocation(location.pathname, 'gray')}></div>
            <div className='ColorRed' onClick={() => GoLocation(location.pathname, 'red')}></div>
            <div className='ColorGreen' onClick={() => GoLocation(location.pathname, 'green')}></div>
            <div className='ColorWhite' onClick={() => GoLocation(location.pathname, 'white')}></div>
            <div className='ColorBrown' onClick={() => GoLocation(location.pathname, 'brown')}></div>
            <div className='ColorHacky'onClick={() => GoLocation(location.pathname, 'camo')}></div>
            <div className='ColorAll'onClick={() => GoLocation(location.pathname, '')}> Все</div>
        </div>
      </div>
    )
  
}
// navigate('/catalog/pr/red')

export default ColorSort;