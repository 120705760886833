import React, { Fragment, useContext, useState } from 'react';
import { CustomContext } from '../../config/context/context';
import './Basket.css'
import Card from '../Card/Card';
import BasketForm from '../BasketForm/BasketForm';
import BasketFormFinish from '../BasketFormFinish/BasketFormFinish';


const Basket = () => {
  
  const {basket, CartCount,showForm, setShowForm} = useContext(CustomContext)
  // const [show, setShow] = useState(false)

  const CardSeeBasket = (item) => (
    <Fragment key={item.id}>
      <Card item={item}/>
    </Fragment>
)

  return (
    <div className='CatalogWrapper'>
      <div className='basket__size'>
        <div className='basket__size__title'>Корзина</div> 
        {(CartCount === 0) ? <div className='basket__null'>Корзина пока пуста</div> : ''}
        { basket.map((item) =>( CardSeeBasket(item) ))
            } 
      </div>
      <div className='basket__button__form'>
        {(CartCount !== 0) ? <button onClick={() => setShowForm(1)}>оформить</button> : ''}
        {/* <button onClick={() => setShow(!show)}>оформить</button> */}
      </div>
      {showForm === 1  && <div className='BasketForm'><BasketForm /></div> }
      {showForm === 2  && <div className='BasketForm'><BasketFormFinish /></div> }
    </div>
  );
};

export default Basket;