import React from 'react'
import './Footer.css'


const Footer = () => {
    
    return (
      <div className='BackgroundSizeFooter'>
        <div className='Footer__text'>
            © 2024 CUSTOMCAP | About | Contact
        </div>
      </div>
    )
  
}

export default Footer;