import React from 'react'
import './Home.css'
import Carousel from '../../components/Carousel/Carousel';
import HighCatalog from '../../components/HighCatalog/HighCatalog';
import History from '../../components/History/History';
import Portfolio from '../../components/Portfolio/Portfolio';


const Home = () => {

    // const location = useLocation()

    return(
        <main>
            <Portfolio />
            <Carousel />
            <div   className="CatalogWrapper">
                <HighCatalog />
                <History />
                {/* {(location.pathname !== '/' || location.pathname !=='/catalog/pr' || location.pathname !=='/catalog/iz') ? <Catalog /> : ''} */}
            </div>
            
        </main>      
  );
}


export default Home;
