import React, { useContext } from 'react'
import { MdShoppingCart } from "react-icons/md";
import './header.css'
import { useNavigate } from 'react-router-dom';
import { CustomContext } from '../../../config/context/context';
import Navbar from '../../Navbar/Navbar';

const Header = () => {

    const navigate= useNavigate()
    const {CartCount} = useContext(CustomContext)


    return (
      <div className='BackgroundSize'>
        <div className='HeaderBig'>
          <div className="wrapper" onClick={() => navigate('/')}>
            <svg>
              <text x="50%" y="50%" dy=".35em" text-anchor="middle">
                Custom Cap
              </text>
            </svg>
          </div>
          
          
          <div className='Navbar'>
            <div className='Navbar__cart'>
              <MdShoppingCart className='shop-cart-button'  onClick={() => navigate('/cart')}/> {/* <MdShoppingCart />  onClick={() => setCartOpen(cartOpen = !cartOpen)} className={`shop-cart-button ${cartOpen && 'active'}`}/> */}
                    {(CartCount > 0 ) ? <div className='Navbar__cart__count'>{CartCount}</div> : ''} 
            </div>
            {/* <ul>
              <div className='Navbar__cart'> 
               <MdShoppingCart className='shop-cart-button'  onClick={() => navigate('/cart')}/>  */}
               {/* <MdShoppingCart />  onClick={() => setCartOpen(cartOpen = !cartOpen)} className={`shop-cart-button ${cartOpen && 'active'}`}/> */}
                {/* {(CartCount > 0 ) ? <div className='Navbar__cart__count'>{CartCount}</div> : ''}  
              </div>
              <li>Контакты</li>
              <li>Доставка</li>
            </ul> */}
            <Navbar/>
          </div>

        </div>
      </div>
    )
  
}

export default Header;